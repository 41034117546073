<template>
  <div>
    <div>
      <b-row class="d-flex align-items-start justify-content-between py-1">
        <b-col md="8" class="bg-colorWhite pt-1" style="border-radius: 20px">
          <b-row class="d-flex align-items-center px-2">
            <b-col>
              <h3 class="font-weight-bolder text-colorBlack m-0 p-0">
                Math with Max Carter.
              </h3>
            </b-col>
            <b-col class="text-right">
              <b-link :to="{ name: 'LectureStats' }">
                <img src="@/assets/images/icons/lecture/shipment.svg" img-fluid
              /></b-link>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center mt-2">
            <b-col md="12">
              <img
                src="@/assets/images/icons/dashboard/oxar.png"
                height="420px"
                width="100%"
                img-fluid
              />
            </b-col>
          </b-row>
          <b-row
            class="bg-colorBgGray py-2 mt-2 d-flex justify-content-center"
            style="border-radius: 20px"
          >
            <b-col md="2" sm="2">
              <div class="text-center">
                <img
                  src="@/assets/images/icons/lecture/soundOn.svg"
                  img-fluid
                />
              </div>
              <div class="text-center">
                <p
                  class="font-weight-bolder text-colorBlack"
                  style="font-size: 0.8rem"
                >
                  CMD + M
                </p>
              </div>
            </b-col>
            <b-col md="2" sm="2">
              <div class="text-center">
                <img
                  src="@/assets/images/icons/lecture/soundOff.svg"
                  img-fluid
                />
              </div>
              <div class="text-center">
                <p
                  class="font-weight-bolder text-colorBlack"
                  style="font-size: 0.8rem"
                >
                  CMD + M
                </p>
              </div>
            </b-col>
            <b-col md="2" sm="2">
              <div class="text-center">
                <img
                  src="@/assets/images/icons/lecture/soundCross.svg"
                  img-fluid
                />
              </div>
              <div class="text-center">
                <p
                  class="font-weight-bolder text-colorBlack"
                  style="font-size: 0.8rem"
                >
                  CMD + M
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          align-self="stretch"
          md=""
          class="bg-colorWhite text-right ml-1"
          style="border-radius: 20px"
        >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style></style>
